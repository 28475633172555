
.car-left {
    margin-right: -110px;
    position: relative;
    z-index: 1;
}

.car-left img {
    width: 100%;
    border-radius: 10px;
}


.car-right {
    background: #E8FAFF;
    border-radius: 8px;
    padding: 80px 80px 80px 140px;
    margin-top: 200px;
}

.career-ser-2 {
    margin-top: 100px;
}

.car-off .appl-form {
    mix-blend-mode: normal;
    border: 2px solid #E5E5E5;
    border-radius: 10px;
    max-width: 1020px;
    margin: 0px auto;
    padding: 90px 60px;
}

.car-off .appl-form .form-control {
	margin-bottom: 40px;
	border: 2px solid #EBEAED;
	box-sizing: border-box;
	border-radius: 7px;
	font-weight: bold;
	font-size: 14px;
	line-height: 34px;
	/* identical to box height, or 186% */
	letter-spacing: 2px;
	/*text-transform: uppercase;*/
	color: #9295BA;
}

.car-off .appl-form .form-select {
	margin-bottom: 40px;
	border: 2px solid #EBEAED;
	box-sizing: border-box;
	border-radius: 7px;
	font-weight: bold;
	font-size: 14px;
	line-height: 34px;
	/* identical to box height, or 186% */
	letter-spacing: 2px;
	/*text-transform: uppercase;*/
	color: #9295BA;
}

.car-bene h4 {
	font-weight: 600;
font-size: 36px;
line-height: 118%;
/* or 42px */

text-align: center;

color: #1F1F1F;
} 
.car-bene p {
	font-weight: normal;
font-size: 18px;
line-height: 23px;
/* or 131% */

text-align: center;
letter-spacing: 0.04em;

color: #000000;
}
.bene-list{
	text-align: center;
	margin: 40px 0;
}
.bene-list i{
	font-size: 32px;
line-height: 38px; 
color: #ff565b;

}
.bene-list h5{
	font-weight: 600;
font-size: 18px;
line-height: 28px;
/* or 156% */

text-align: center;

color: #000000;


}
.bene-list p{
	font-weight: normal;
font-size: 16px;
line-height: 26px;
/* or 162% */

text-align: center;

color: #7a7a7a;

mix-blend-mode: normal;

}

.car-bene{
	margin-bottom: 100px;
}

@media (max-width:992px) {
	.career-bnr {
		padding:0 15px;
		padding-top: 100px;
	}
	.career-bnr img {
		height: calc(100vh - 100px);
		object-fit: cover;
		object-position: center;
	}
	.car-right {
		padding: 35px;
		margin-top: 0;
	}
	.car-left {
		margin-right: 0px;
	}
	.car-off .accordion-button span {
		right: unset;
		left: 20px;
		bottom: 15px;
		font-size: 14px;
		display: block;
		width: calc(100% - 40px);
		text-align: left;
	}
	.car-off .accordion-button {
		padding-bottom: 40px;
	}
	.car-off .appl-form {
		padding: 50px 30px 30px;
	}
}