.credit{
    font-size: 12px;
  }

  .brand-logo{
    width: 250px;
    height: auto;
  }

  .clientslider img{
    width: 160px;
    height: auto;
  }

  .single-logo img {
    margin: 0 auto; /* it centers any block level element */
  }


  @media (max-width: 992px) {
    
    .footerLogo{
  width: 50%;
  height: auto;
    }
  }