a,
p,
h1,
h2,
h3,
h4,
ul {
  margin: 0;
  padding: 0;
}

.show-on-map{
  color: blanchedalmond !important;
  cursor: pointer;
}
.show-on-map i{
font-size: 24px;
}







